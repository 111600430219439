import React from 'react';
import { Link } from "gatsby";
import LinkArrowSvg from '../images/link-arrow';

const LetsConnect = ({data, mode}) => {
    const createMarkup = (content) => {
        return { __html: content };
    }; 
    return (
        <section className={`lg:py-[120px] md:py-[100px] py-[80px] ${mode === "light" ? 'bg-[#fff]' : 'bg-[#FAFBFB]'}`}>
            <div className='container'>
                <div className={`flex justify-between ${mode === "light" ? 'flex-col lg:flex-row lg:items-center md:gap-[50px] lg:gap-[0] gap-0' : 'flex-col md:flex-row md:items-center'}`}>
                    <h2 className='md:mb-0 font-medium font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36] lg:w-[75%] md:w-[65%] w-full mb-[40px]'
                        dangerouslySetInnerHTML={createMarkup(data.title)}
                    >
                    </h2>
                    <Link to={`${data.link}`} title={data.linkTitle ? data.linkTitle : 'contact us'} className='relative md:font-medium lg:font-normal font-normal lg:pr-[51px] md:pr-[40px] pr-[36px] lg:text-[21px] md:text-[16px] text-[15px] md:leading-[19.36px] lg:leading-[25.41px] leading-[26px] footer-link hover:text-[#53D4B5] transition hover:transition-all duration-300 hover:duration-300 tracking-[.03em] flex items-center max-w-fit'>
                        {data.text} 
                        <span className='absolute top-[50%] -translate-y-[50%] left-[calc(100%-26px)] md:left-[calc(100%-28px)] lg:left-[calc(100%-36px)] rotate-45 flex items-center justify-center h-[26px] w-[26px] md:w-[28px] md:h-[28px] lg:w-[36px] lg:h-[36px] bg-[#53D4B5] hover:transition-all transition-all duration-300 hover:duration-300 rounded-[50px]'>
                            <LinkArrowSvg size={15.58} fill='#fff' />
                        </span>
                    </Link>
                </div>
            </div>
        </section>
    )
}
export default LetsConnect;
