import React, { useState, useEffect } from "react";
import ProjectSection from "../components/ProjectSection";
import ServiceTab from "../components/ServiceTab";
import Testimonials from "../components/Testimonials";
import LetsConnect from "../components/LetsConnect";
// import KnowMoreSection from "../components/KnowMoreSection";
import { Seo } from "../components/seo";
import Layout from "../components/layout";
const Projects = () => {
  const projectData = [
    {
      title: "Elastic Sign",
      desc: "A Digital Signature Platform",
      Industry: "SaaS",
      firstAlt: "UI Design Of Document Management Screen",
      firstTitle: "Document Management Screen Design",
      secondAlt: "UI UX Design To Sign Document Digitally",
      secondTitle: "E-Sign Documents",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "React Development",
          link: "/reactjs-development/",
        },
      ],
      link: "/project/elastic-sign/",
      images: ["elastic-sign.webp", "elastic-sign-2.webp"],
    },
    {
      title: "Survey2Connect",
      desc: "A Research Tech Platform",
      Industry: "Enterprise",
      firstAlt: "UI UX Design To Create Survey",
      firstTitle: "Survey Creation Screen",
      secondAlt: "Ui Design To Create Questionnaire For Survey",
      secondTitle: "Questionnaire For Survey",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "UI Development",
          link: "/ui-development/",
        },
      ],
      link: "/project/survey2connect/",
      images: ["survey.webp", "survey-2.webp"],
    },
    {
      title: "Stride",
      desc: "A Blood Bank Management Platform",
      Industry: "HealthTech",
      firstAlt: "All Donor's List",
      firstTitle: "Donor's List",
      secondAlt: "UI Screen Of Donor Details",
      secondTitle: "Donor Details",
      WhatWeDid: [
        {
          title: "Digital Transformation",
          link: "/digital-transformation/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Usability Testing",
          link: "/usability-testing/",
        },
      ],
      link: "/project/stride/",
      images: ["stride.webp", "stride-2.webp"],
    },
    {
      title: "Hala Gig",
      desc: "A Consumer Experience Platform",
      Industry: "SaaS",
      firstAlt: "Mobile UI Design To Collect Customer's Response",
      firstTitle: "Feedback Collection Screen",
      secondAlt: "UI Design Of Agent's Account",
      secondTitle: "Agent's Account",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "React Development",
          link: "/reactjs-development/",
        },
      ],
      link: "/project/ui-ux-design-halagig/",
      images: ["hala-gig.webp", "hala-gig-2.webp"],
    },
    {
      title: "LikeHire",
      desc: "A Healthcare Job Portal Platform",
      Industry: "HealthTech",
      firstAlt: "Landing Page Of Healthcare Job Platform",
      firstTitle: "Landing Page",
      secondAlt: "UI Design Of Total No of Job Applicants",
      secondTitle: "Applicant's Screen",
      WhatWeDid: [
        {
          title: "Branding",
          link: "/branding/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "VueJs Development",
          link: "/vuejs-development/",
        },
      ],
      link: "/project/ui-ux-design-likehire/",
      images: ["like-hire.webp", "like-hire-2.webp"],
    },
    {
      title: "Visa",
      desc: "A Campaign Monitoring Platform",
      Industry: "Enterprise",
      firstAlt: "UI UX Screen To Monitor Campaigns",
      firstTitle: "Projects Overview",
      secondAlt: "UI Dashboard To Monitor Each Campaign Performance",
      secondTitle: "Dashboard",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },

        {
          title: "UX Consultation",
          link: "/ux-consulting/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
      ],
      link: "/project/campaign-monitoring-platform-design/",
      images: ["visa-work.webp", "visa-work-2.webp"],
    },
    {
      title: "Icegram",
      desc: "An Email Marketing Tool",
      Industry: "SaaS",
      firstAlt:
        "UI UX Screen to get an overview of the email marketing campaign's performance. ",
      firstTitle: "Campaign Performance",
      secondAlt:
        "In-built email marketing templates and an option to create customised templates.",
      secondTitle: "E-mail Marketing Templates",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
      ],
      link: "/project/icegram/",
      images: ["icegram.webp", "icegram-2.webp"],
    },
    {
      title: "iGaming",
      desc: "Platform To Track Affiliates With Omni-Channel Tracking Feature",
      Industry: "SaaS",
      firstAlt: "Manage Affilliates",
      firstTitle: "Manage Affilliates",
      secondAlt: "Affilliate Activities",
      secondTitle: "Affilliate Activities",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
      ],
      link: "/project/igaming/",
      images: ["igaming1.webp", "igaming2.webp"],
    },
    {
      title: "Gizmo",
      desc: "A Market Research Platform",
      Industry: "Enterprise",
      firstAlt: "UI UX Dashboard To Analyze Specific Market Survey",
      firstTitle: "Survey Dashboard",
      secondAlt: "Design Components Used To Create Survey",
      secondTitle: "Design Components",
      WhatWeDid: [
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Rapid Prototyping",
          link: "/rapid-prototyping/",
        },
        {
          title: "Dashboards and Data Visualisation",
          mobileTitle: "Data Visulisation",
          link: "/data-visualisation/",
        },
      ],
      link: "/project/gizmo/",
      images: ["gizmo.webp", "gizmo-2.webp"],
    },
    // {
    //   title: "Vet IT",
    //   desc: "A Cloud-Based Practice Management Software",
    //   Industry: "HealthTech",
    //   firstAlt: "UI UX Screen To Monitor Insurance Claim",
    //   firstTitle: "Insurance Claim Dashboard",
    //   secondAlt: "Insurance Claim Dashboard Of Specific Account",
    //   secondTitle: "Insurance Details",
    //   WhatWeDid: [
    //     {
    //       title: "Design Audit",
    //       link: "/design-audit/",
    //     },
    //     {
    //       title: "UI UX Designing",
    //       link: "/ui-ux-designing/",
    //     },
    //     {
    //       title: "UI Development",
    //       link: "/ui-development/",
    //     },
    //   ],
    //   link: "/project/vet-clinic",
    //   images: ["vet-it.webp", "vet-it-2.webp"],
    // },
    {
      title: "User Guides",
      desc: "A Customer Success Management Platform",
      Industry: "Enterprise",
      firstAlt: "UI UX Screen To Monitor Navigations",
      firstTitle: "Navigations Screen",
      secondAlt: "Details Of Naviagtions",
      secondTitle: "Naviagtion Results",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Dashboards and Data Visualisationn",
          mobileTitle: "Data Visulisation",
          link: "/data-visualisation/",
        },
      ],
      link: "/project/customer-success-management-platform-design/",
      images: ["user-guides.webp", "user-guides-2.webp"],
    },
    {
      title: "Mom’s Belief",
      desc: "An E-Learning Platform",
      Industry: "Enterprise",
      firstAlt: "Landing Page Of E-learning Platform",
      firstTitle: "Landing Page",
      secondAlt: "Complete Details Of Programs",
      secondTitle: "Programs Details",
      WhatWeDid: [
        {
          title: "UX Consultation",
          link: "/ux-consulting/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Usability Testing",
          link: "/usability-testing/",
        },
      ],
      link: "/project/ui-ux-design-moms-belief/",
      images: ["moms-belief.webp", "moms-belief-2.webp"],
    },
    {
      title: "Kantime",
      desc: "A Hospice EMR Solutions Platform",
      Industry: "HealthTech",
      firstAlt: "UI UX Mobile Screen To Monitor Daily Schedule And Performance",
      firstTitle: "Performance And Schedule Screen",
      secondAlt: "Mobile Features And Account Details Of Physician",
      secondTitle: "Accounts Details",
      WhatWeDid: [
        {
          title: "UX Consultation",
          link: "/ux-consulting/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Design System Engineering",
          mobileTitle: "Design System",
          link: "/design-systems/",
        },
      ],
      link: "/project/kantime/",
      images: ["kantime-project.webp", "kantime-project-2.webp"],
    },
    {
      title: "KYSS",
      desc: "An accounting platform for MSME's",
      Industry: "FinTech",
      firstAlt: "UI UX Mobile Screen Of Accounting Platform",
      firstTitle: "Landing Page",
      secondAlt: "Past Invoice Details",
      secondTitle: "All Invoices",
      WhatWeDid: [
        {
          title: "Branding",
          link: "/branding/",
        },
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
      ],
      link: "/project/kyss/",
      images: ["kyss.webp", "kyss-2.webp"],
    },
    {
      title: "Fio",
      desc: "A D2C fashion e-commerce platform",
      Industry: "Ecommerce",
      firstAlt: "Landing Page Of D2C Fashion E-Commerce Platform",
      firstTitle: "Landing Page",
      secondAlt: "Product List Page Of D2C Fashion E-Commerce Platform",
      secondTitle: "Product List Page",
      WhatWeDid: [
        {
          title: "Branding",
          link: "/branding/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "UI Development",
          link: "/ui-development/",
        },
      ],
      link: "/project/fio/",
      images: ["fio.webp", "fio.webp"],
    },
    {
      title: "Landscape Pro",
      desc: "A Property Management Platform",
      Industry: "SaaS",
      firstAlt: "Calender UI UX Design",
      firstTitle: "Schedule Screen",
      secondAlt: "UI Design Manage Users",
      secondTitle: "Manage Users",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Design Audit",
          link: "/design-audit/",
        },
      ],
      link: "/project/landscape-pro/",
      images: ["landscape-pro.webp", "landscape-pro-2.webp"],
    },
    {
      title: "Unilogix App",
      desc: "A Logistics Insights Application",
      Industry: "Logistics",
      firstAlt: "Mobile UI UX Screen Of Logistics Insight Application",
      firstTitle: "Logistics Services List",
      secondAlt: "Order And Warehouse Details",
      secondTitle: "Order Details",
      WhatWeDid: [
        {
          title: "Branding",
          link: "/branding/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "UI Development",
          link: "/ui-development/",
        },
      ],
      link: "/project/logistics-insight-app-design/",
      images: ["uni-logics.webp", "uni-logics-2.webp"],
    },
    {
      title: "Alpha Test",
      desc: "A no-code test automation platform",
      Industry: "Enterprise",
      firstAlt: "UI UX No-Code Test Automation Dashboard",
      firstTitle: "Automation Dashboard",
      secondAlt: "Test Run Results of Specific Projects",
      secondTitle: "Test Run Results",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Usability Testing",
          link: "/usability-testing/",
        },
      ],
      link: "/project/alpha-test/",
      images: ["alpha-test.webp", "alpha-test-2.webp"],
    },
    {
      title: "Hushly",
      desc: "A B2B MarTech Platform",
      Industry: "Martech",
      firstAlt: "UI UX Product Page For B2B Martech Platform",
      firstTitle: "Product Page",
      secondAlt: "Product Details Page For Specific Topics",
      secondTitle: "Product Details Page",
      WhatWeDid: [
        {
          title: "UX Consultation",
          link: "/ux-consulting/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Design System Engineering",
          mobileTitle: "Design System",
          link: "/design-systems/",
        },
      ],
      link: "/project/hushly/",
      images: ["hushly-project.webp", "hushly-project-2.webp"],
    },
    {
      title: "Auto Service Booking",
      desc: "A CRM Platform For Car Servicing",
      Industry: "SaaS",
      firstAlt: "UI Screen To Schedule For Car Servicing",
      firstTitle: "Calender",
      secondAlt: "UI Dashboard To Overview Car Servicing Performance",
      secondTitle: "Performance Dashboard",
      WhatWeDid: [
        {
          title: "Product Development",
          link: "/product-development/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "User Acquisition Consultation",
          mobileTitle: "User Consultation",
          link: "/user-acquisition-consulting/",
        },
      ],
      link: "/project/ui-ux-auto-service-booking/",
      images: ["auto-service-web.webp", "auto-service-web-2.webp"],
    },
    {
      title: "Nirvana",
      desc: "A Financial Solutions Platform",
      Industry: "FinTech",
      firstAlt: "UI UX Orders List Screen",
      firstTitle: "Orders List",
      secondAlt: "UI UX Screen To Show The Order Status",
      secondTitle: "Order Status",
      WhatWeDid: [
        {
          title: "Design Audit",
          link: "/design-audit/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "React Development",
          link: "/reactjs-development/",
        },
      ],
      link: "/project/nirvana/",
      images: ["nirvana-casestudy.webp", "nirvana-casestudy-2.webp"],
    },
    {
      title: "Pay Invoice",
      desc: "An AI-based comprehensive P2P solution",
      Industry: "FinTech",
      firstAlt: "Login Screen For P2P Platform",
      firstTitle: "Login Screen",
      secondAlt: "UI UX Dashboard to check the compliance status",
      secondTitle: "Dashboard",
      WhatWeDid: [
        {
          title: "Branding",
          link: "/branding/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Wordpress Development",
          link: "/wordpress-development/",
        },
      ],
      link: "/project/ui-ux-design-payinvoice/",
      images: ["pay-invoice.webp", "pay-invoice-2.webp"],
    },
    {
      title: "Developly",
      desc: "A Code E-Learning Platform",
      Industry: "Enterprise",
      firstAlt: "User Dashboard To Chek Their Code E-learning Status",
      firstTitle: "User Dashboard",
      secondAlt:
        "UI Screen To Check The Progress Of The User For A Particular Course",
      secondTitle: "Progress Screen",
      WhatWeDid: [
        {
          title: "Branding",
          link: "/branding/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "UI Development",
          link: "/ui-development/",
        },
      ],
      link: "/project/ui-ux-design-developly/",
      images: ["developly-web.webp", "developly-2.webp"],
    },
    {
      title: "S2C Help Manual",
      desc: "A Research Tech Platform",
      Industry: "Enterprise",
      firstAlt: "UI UX Screen To Create Survey",
      firstTitle: "Create Survey",
      secondAlt:
        "UI Screen To Create Personalized Survey Using Specified Categories",
      secondTitle: "Select Category",
      WhatWeDid: [
        {
          title: "UX Consultation",
          link: "/design-audit/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Wordpress Development",
          link: "/wordpress-development/",
        },
      ],
      link: "/project/s2c-help-manual/",
      images: ["help-manual.webp", "help-manual-2.webp"],
    },
    {
      title: "Ship Delight",
      desc: "An E-Commerce Logistic Website",
      Industry: "Logistics",
      firstAlt: "UI UX Dashbaord To Check The Real Time Shipment Status",
      firstTitle: "Real Time Shipment Status",
      secondAlt: "UI Dashboard To Check The Performance Status",
      secondTitle: "NDR Status",
      WhatWeDid: [
        {
          title: "Branding",
          link: "/branding/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Wordpress Development",
          link: "/wordpress-development/",
        },
      ],
      link: "/project/shipdelight/",
      images: ["ship-delight-web.webp", "ship-delight-web-2.webp"],
    },
    {
      title: "Logo Yazilim",
      desc: "A GST Billing Software",
      Industry: "FinTech",
      firstAlt: "UI UX dashboard to check the invoice status",
      firstTitle: "Invoice status dashboard",
      secondAlt: "UI Screen to create invoice",
      secondTitle: "Create Invoice",
      WhatWeDid: [
        {
          title: "UX Consultation",
          link: "/ux-consulting/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "UI Development",
          link: "/ui-development/",
        },
      ],
      link: "/project/logo-yazilim/",
      images: ["captain-biz.webp", "captain-biz-2.webp"],
    },
    {
      title: "Kesari",
      desc: "An ERP Platform",
      Industry: "Enterprise",
      firstAlt: "UI UX dashboard to check the booking status",
      firstTitle: "Bookings Overview",
      secondAlt: "UI screen to check various travel packages",
      secondTitle: "All Packages Screen",
      WhatWeDid: [
        {
          title: "Digital Transformation",
          link: "/digital-transformation/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Angular Development",
          link: "/angularjs-development/",
        },
      ],
      link: "/project/ui-ux-design-kesari-tap/",
      images: ["kesari-web.webp", "kesari-2.webp"],
    },
    {
      title: "Auto Recruits",
      desc: "A Job-Portal Platform",
      Industry: "SaaS",
      firstAlt: "UI UX Dashboard to overview applicants status",
      firstTitle: "Job Applicants Overview",
      secondAlt: "Applicants list for a specific job position",
      secondTitle: "Applicants list",
      WhatWeDid: [
        {
          title: "Product Development",
          link: "/product-development/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "UI Development",
          link: "/ui-development/",
        },
      ],
      link: "/project/ui-ux-design-auto-recruits/",
      images: ["auto-recruits.webp", "auto-recruits-2.webp"],
    },
    {
      title: "Energy Trade",
      desc: "An Energy Trading Platform",
      Industry: "Enterprise",
      firstAlt: "Order Summary UI screen for Energy Trading",
      firstTitle: "Order Summary",
      secondAlt: "Screen to watch market status for trading energy",
      secondTitle: "Market Watch",
      WhatWeDid: [
        {
          title: "UX Consultation",
          link: "/ux-consulting/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Design Audit",
          link: "/design-audit/",
        },
      ],
      link: "/project/ui-ux-design-energy-trade/",
      images: ["energy-trade.webp", "energy-trade-2.webp"],
    },
    {
      title: "BAA Platform",
      desc: "A Platform for Buying Agents",
      Industry: "SaaS",
      firstAlt: "UI UX Newsfeed screen to share announcements, events, etc",
      firstTitle: "Manage Newsfeed",
      secondAlt: "Agents Directory to showcase their details",
      secondTitle: "Agent’s Directory",
      WhatWeDid: [
        {
          title: "UX Consultation",
          link: "/ux-consulting/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Wordpress Development",
          link: "/wordpress-development/",
        },
      ],
      link: "/project/ui-ux-design-baa-platform/",
      images: ["baa.webp", "baa-2.webp"],
    },
    {
      title: "Pro Employee",
      desc: "An Employee Assessment Platform",
      Industry: "SaaS",
      firstAlt:
        "UI UX of Employee Assessment platform for various job positions",
      firstTitle: "Assessment Platform",
      secondAlt: "Job Description of particular job position",
      secondTitle: "Job Description",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "UI Development",
          link: "/ui-development/",
        },
      ],
      link: "/project/employee-assessment-platform-design/",
      images: ["pro-employee.webp", "pro-employee-2.webp"],
    },
    {
      title: "KYS",
      desc: "Supplier GST Performance Platform",
      Industry: "SaaS",
      link: "/project/ui-ux-design-kys/",
      firstAlt: "UI UX of supplier’s GST compliance overview screen",
      firstTitle: "Dashboard",
      secondAlt: "Suppliers GST compliance solutions",
      secondTitle: "Suppliers Solutions",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Angular Development",
          link: "/angularjs-development/",
        },
      ],
      images: ["kys.webp", "kys-2.webp"],
    },
    {
      title: "CloudForceHr",
      desc: "A SaaS Based HRMS Software",
      Industry: "Enterprise",
      link: "/project/ui-ux-design-cloudforcehr/",
      firstAlt: "UI UX screen to configure employee data to HRMS Software",
      firstTitle: "Configuration Screen",
      secondAlt: "UI Screen to take bulk actions on employee’s data",
      secondTitle: "Bulk Action on Employees Data",
      WhatWeDid: [
        {
          title: "Digital Transformation",
          link: "/digital-transformation/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Angular Development",
          link: "/angularjs-development/",
        },
      ],
      images: ["cloudforce.webp", "cloudforce-2.webp"],
    },
    {
      title: "Distribution Management System",
      desc: "A platform for inventory allocation and routing",
      Industry: "SaaS",
      link: "/project/distribution-management-system/",
      firstAlt: "UI UX to manage, route and allocate inventory",
      firstTitle: "Manage Inventory",
      secondAlt: "Check the orders assisgned and completed by drivers",
      secondTitle: "Assigned Bins",
      WhatWeDid: [
        {
          title: "Product Development",
          link: "/product-development/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "UI Development",
          link: "/ui-development/",
        },
      ],
      images: ["dms.webp", "dms-2.webp"],
    },
    {
      title: "Boardmeetingz",
      desc: "An Online Meeting Platform",
      Industry: "SaaS",
      link: "/project/ui-ux-design-boardmeetingz/",
      firstAlt: "Dashboard to check the task list of board members",
      firstTitle: "Dashboard",
      secondAlt: "UI Screen to check the approvals",
      secondTitle: "Approvals",
      WhatWeDid: [
        {
          title: "UX Consultation",
          link: "/ux-consulting/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "UI Development",
          link: "/ui-development/",
        },
      ],
      images: ["board-meeting.webp", "board-meeting-2.webp"],
    },
    {
      title: "Forex",
      desc: "An In-Store Forex And CRM System",
      Industry: "SaaS",
      link: "/project/ui-ux-design-pos-forex/",
      firstAlt:
        "UI Screen of Customer profile to check their exchange information",
      firstTitle: "Customer Profile",
      secondAlt: "Price calculator to check the market exchange rate",
      secondTitle: "Price Calculator",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "UI Development",
          link: "/ui-development/",
        },
      ],
      images: ["forex-casestudy.webp", "forex-casestudy-2.webp"],
    },
    {
      title: "Style Spot",
      desc: "An E-Commerce Platform",
      Industry: "Enterprise",
      link: "/project/ui-ux-design-style-spot/",
      firstAlt:
        "Dashboard to check real-time performance and logistics performance",
      firstTitle: "Logistics Performance",
      secondAlt:
        "UI Screen of Service Provider Details, thier status, performance, etc.",
      secondTitle: "Service Provider Details",
      WhatWeDid: [
        {
          title: "UX Consultation",
          link: "/ux-consulting/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "UI Development",
          link: "/ui-development/",
        },
      ],
      images: ["shoppers-stop.webp", "shoppers-stop-2.webp"],
    },
    {
      title: "Sunday Dealz",
      desc: "A Flash Sales E-Commerce Platform",
      Industry: "Ecommerce",
      link: "/project/ui-ux-design-sunday-dealz/",
      firstAlt: "Product List Page of Flash Sale E-commerce platform",
      firstTitle: "Product List Page",
      secondAlt: "Order and payment details of the customer",
      secondTitle: "Payment Details",
      WhatWeDid: [
        {
          title: "Branding",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "UI Development",
          link: "/ui-development/",
        },
      ],
      images: ["sunday.webp", "sunday-2.webp"],
    },
    {
      title: "Align Plus",
      desc: "Software For Management Consultancy",
      Industry: "SaaS",
      link: "/project/ui-ux-design-align-plus/",
      firstAlt: "Login Screen of a software for management Consultancy",
      firstTitle: "Login Screen",
      secondAlt: "Camapign Assessment status of the user",
      secondTitle: "Campaign Assessment",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Angular Development",
          link: "/angularjs-development/",
        },
      ],
      images: ["align-plus.webp", "align-plus-2.webp"],
    },
    {
      title: "Trackier",
      desc: "A platform to optimise marketing performance",
      Industry: "SaaS",
      link: "/project/trackier/",
      firstAlt: "UI UX Design to track realtime marketing performance",
      firstTitle: "Dashboard",
      secondAlt: "UI Screen To Ceate and View Widgets",
      secondTitle: "Widget Builder",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
      ],
      images: ["trackier-1.webp", "trackier-2.webp"],
    },
    {
      title: "Sangti",
      desc: "tech-first carbon management platform",
      Industry: "SaaS",
      link: "/project/sangti/",
      firstAlt: "Sangti",
      firstTitle: "Sangti",
      secondAlt: "Sangti",
      secondTitle: "Sangti ",
      WhatWeDid: [
        {
          title: "UX Audit",
          link: "/design-audit/",
        },
        {
          title: "UX Research ",
          link: "/user-research/",
        },
        {
          title: "UI Design",
          link: "/ui-ux-designing/",
        }
      ],
      images: ["sangti-1.webp", "sangti-2.webp"],
    },
    {
      title: "CSL",
      desc: "End-to-End Logistics Service Platform",
      Industry: "SaaS",
      link: "/project/logistics-website-designing/",
      firstAlt: "CSL",
      firstTitle: "CSL",
      secondAlt: "CSL",
      secondTitle: "CSL ",
      WhatWeDid: [
        {
          title: "UX Research ",
          link: "/user-research/",
        },
        {
          title: "UI Design",
          link: "/ui-ux-designing/",
        },
        {
          title: "UI Development",
          link: "/ui-development/",
        },
      ],
      images: ["fsl-1.webp", "fsl-2.webp"],
    },
    {
      title: "Kantime LMS",
      desc: "Home Health and Hospice EMR Solution",
      Industry: "SaaS",
      link: "/project/kantime-lms/",
      firstAlt: "Kantime LMS",
      firstTitle: "Kantime LMS",
      secondAlt: "Kantime LMS",
      secondTitle: "Kantime LMS ",
      WhatWeDid: [
        {
          title: "UX Research ",
          link: "/user-research/",
        },
        {
          title: "UX/UI Design",
          link: "/ui-ux-designing/",
        },
        {
          title: "UI Development",
          link: "/ui-development/",
        },
      ],
      images: ["kantime-lms-1.webp", "kantime-lms-2.webp"],
    },
    {
      title: "Rentcity",
      desc: "All-in-one property management tool",
      Industry: "SaaS",
      link: "/project/rentcity/",
      firstAlt: "Rentcity",
      firstTitle: "Rentcity",
      secondAlt: "Rentcity",
      secondTitle: "Rentcity ",
      WhatWeDid: [
        {
          title: "UX Research ",
          link: "/user-research/",
        },
        {
          title: "UX/UI Design",
          link: "/ui-ux-designing/",
        }
      ],
      images: ["rentcity-1.webp", "rentcity-2.webp"],
    },
  ];
  const tabs = [
    `All Projects (${projectData.length})`,
    `Enterprise (${
      projectData.filter(
        (item) =>
          item.Industry === "Enterprise" ||
          item.Industry === "Martech" ||
          item.Industry === "HealthTech"
      ).length
    })`,
    `SaaS (${
      projectData.filter(
        (item) =>
          item.Industry === "SaaS" ||
          item.Industry === "Research Tech" ||
          item.Industry === "FinTech"
      ).length
    })`,
    `Ecommerce (${
      projectData.filter(
        (item) => item.Industry === "Ecommerce" || item.Industry === "Logistics"
      ).length
    })`,
  ];
  // All Projects Enterprise SaaS Ecommerce
  const info = {
    title: "Let’s start with A conversation",
    link: "/contact-us/",
    text: "Contact Us",
  };
  const [animation, setAnimation] = useState(true);

  const [industryFilter, setIndustryFilter] = useState("");
  const [filteredProjectData, setFilteredProjectData] = useState(projectData);
  const onChangeTab = (data) => {
    if (data === "All Projects") {
      setIndustryFilter(data);
      setTimeout(() => {
        setFilteredProjectData(projectData);
      }, 100);
    } else {
      setIndustryFilter(data);
    }
  };
  useEffect(() => {
    if (industryFilter !== "") {
      if (!animation) {
        setTimeout(() => {
          if (industryFilter !== "All Projects") {
            const filteredProjectDataEx = projectData.filter((item) => {
              const includeForSaas =
                industryFilter === "Enterprise" &&
                (item.Industry === "Enterprise" ||
                  item.Industry === "Martech" ||
                  item.Industry === "HealthTech");
              const includeForMarchtech =
                industryFilter === "SaaS" &&
                (item.Industry === "SaaS" ||
                  item.Industry === "Research Tech" ||
                  item.Industry === "FinTech");
              const includeForMarchtechs =
                industryFilter === "Ecommerce" &&
                (item.Industry === "Logistics" || item.Industry === "Ecommerce");
              return (
                includeForSaas || includeForMarchtech || includeForMarchtechs
              );
            });
            setFilteredProjectData(filteredProjectDataEx);
          }
        }, 200);
      }
    }
  }, [industryFilter, animation]);

  useEffect(() => {
    setAnimation(true);
    setTimeout(() => {
      setAnimation(false);
    }, 1000);
  }, [industryFilter]);
  return (
    <Layout>
      <div className="service-page">
        <section className="fade-in-animation project-section lg:pb-[200px] md:pb-[120px] pb-[80px] lg:pt-[90px] md:pt-[80px] pt-[60px] text-white bg-[url('../images/guides.webp')] bg-[length:100%] bg-repeat-y bg-top bg-black-main">
          <div
            className={`container lg:mb-[50px] md:mb-[80px] mb-[60px] first-comesup`}
          >
            <h1 className="lg:text-[50px] text-[30px] md:text-[40px] md:leading-[1.3] leading-[normal] font-heading font-medium mb-[30px] md:mb-[30px]">
              Projects
            </h1>
            <ServiceTab tabs={tabs} containWidth onChangeTab={onChangeTab} />
          </div>
          {filteredProjectData.map((data, index) => (
            <div key={index} className="project-list-main">
              <ProjectSection
                num={index}
                lastLength={filteredProjectData}
                data={data}
                animation={animation}
              />
            </div>
          ))}
        </section>
        {/* {filteredProjectData.length > 12 ? (
          <KnowMoreSection
            mode="dark"
            noPadding
            nin="Projects"
            Include="services"
          />
        ) : (
          <KnowMoreSection mode="dark" nin="Projects" Include="services" />
        )} */}
      </div>
    </Layout>
  );
};

export default Projects;

export const Head = () => (
  <>
    <meta name="keywords" content="projects, portfolio, project portfolio, ui ux design, octet design studio" />
    <Seo
      title="UI UX Design Agency- Project Portfolio | Octet Design Studio"
      description="Look into the diversified portfolio of our UI UX Design Agency. We have successfully designed custom UI UX solutions for SaaS, B2B and Enterprises."
    />
  </>
);
